<template>
    <div class="query-table">
        <el-row style="padding:20px 0 10px">
            <el-col :span="6">交易笔数：{{allCount.totalOrderCount || 0}}笔</el-col>
            <el-col :span="6">交易金额：{{allCount.totalTransAmount || 0}}元</el-col>
            <el-col :span="6">结算金额：{{allCount.totalSettleAmount || 0}}元</el-col>
        </el-row>
        <el-table stripe v-loading="loading" :data="dataList" >
            <el-table-column label="订单号" prop="outOrderNo" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="支付类型" prop="payTypeCode" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.payTypeCode | payType}}
                </template>
            </el-table-column>
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <!-- <el-table-column label="一级编号" prop="oneAgentNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="一级名称" prop="oneAgentName" min-width="120" :show-overflow-tooltip="true"/> -->
            <el-table-column label="直属代理商" prop="agentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}} - {{scope.row.agentName}}
                </template>
            </el-table-column>
            <el-table-column label="终端号" prop="sn" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="卡类型" prop="cardType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.cardType | formateCardType}}
                </template>
            </el-table-column>
            <el-table-column label="交易金额(元)" prop="transAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="交易状态" prop="transStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.transStatus=='SUCCESS'?'success':scope.row.transStatus=='FAILED'?'danger':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.transStatus">{{scope.row.transStatus | transStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="交易时间" prop="transTime" min-width="180" :show-overflow-tooltip="true"/>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {TradeApi} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                allCount:''
            }
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await TradeApi.queryPage(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data.orderPageList || [];
                this.allCount = result.data.orderCountInfo || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            async handleUpdate(row) {
                this.showCustomForm = true;
                this.selectRow = row;
                this.isAdd = false;
            },
        }
    };
</script>

<style scoped>

</style>
